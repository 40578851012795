<template>
  <tp-modal v-if="true" name="modal-goods-delivery" max-width="880px">
    <v-card slot-scope="props">
      <v-card-title>
        <div class="font-weight-bold">
          Chi tiết phiếu chuyển hàng #{{ goodsDelivery.code }}
        </div>
        <v-spacer></v-spacer>
        <v-btn
          v-if="goodsDelivery.status !== -1"
          color="red lighten-5 red--text rounded-lg text--accent-2 mr-2"
          depressed
          @click="cancelGoodsDelivery(props.payload.index)"
        >
          Hủy
        </v-btn>
        <v-btn
          v-if="goodsDelivery.status === -2"
          class="cyan lighten-5 rounded-lg mr-2"
          color="primary"
          text
          @click="openModalGoodsDeliveryConfirm(props.payload.index)"
        >
          Xác nhận
        </v-btn>
        <!-- <v-btn class="mr-1" icon color="primary">
          <v-icon>mdi-printer</v-icon>
        </v-btn> -->
        <v-btn icon @click="closeModal()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-container class="grey lighten-3 pa-5" fluid>
        <v-card flat>
          <v-row class="mx-0">
            <v-col class="pa-5">
              <div class="font-weight-bold mb-1">Nhân viên chuyển</div>
              <div class="py-1">
                {{ goodsDelivery.created_user_name }}
              </div>
            </v-col>
            <v-col class="pa-5">
              <div class="font-weight-bold mb-1">Nhân viên nhận</div>
              <div class="py-1">
                {{ goodsDelivery.nguoinhan_name }}
              </div>
            </v-col>
            <v-col class="pa-5">
              <div class="font-weight-bold mb-1">Ngày tạo</div>
              <div class="py-1">
                {{ goodsDelivery.created_at }}
              </div>
            </v-col>
            <v-col class="pa-5">
              <div class="font-weight-bold mb-1">Chỉnh sửa cuối</div>
              <div class="py-1">
                {{ goodsDelivery.updated_at }}
              </div>
            </v-col>
            <v-col class="pa-5">
              <div class="font-weight-bold mb-1">Trạng thái</div>
              <div>
                <v-chip
                  class="text-body-2 font-weight-bold px-2"
                  :color="
                    goodsDelivery.status === 1
                      ? 'green'
                      : goodsDelivery.status === -2
                      ? 'amber'
                      : 'red accent-2'
                  "
                  outlined
                  small
                >
                  {{
                    goodsDelivery.status === 1
                      ? "Thành công"
                      : goodsDelivery.status === -2
                      ? "Chờ xác nhận"
                      : "Đã hủy"
                  }}
                </v-chip>
              </div>
            </v-col>
          </v-row>
        </v-card>

        <v-card class="mt-5 py-4" flat>
          <v-row class="mx-0">
            <v-col class="py-0 px-5">
              <div class="font-weight-bold">
                Chi nhánh chuyển
              </div>
              <div class="primary--text font-weight-medium">
                {{ goodsDelivery.branch_xuat_name }}
              </div>
              <div
                class="text-subtitle-2 font-weight-light grey--text text--darken-2"
              >
                {{ goodsDelivery.branch_xuat_address }}
              </div>
              <div
                v-if="goodsDelivery.note"
                class="text-subtitle-2 font-weight-light yellow lighten-5 rounded px-2 py-1 mt-2"
              >
                <span class="font-weight-medium">Ghi chú:</span>
                {{ goodsDelivery.note }}
              </div>
            </v-col>
            <v-col class="py-0 px-5">
              <div class="font-weight-bold">
                Chi nhánh nhận
              </div>
              <div class="primary--text font-weight-medium">
                {{ goodsDelivery.branch_nhap_name }}
              </div>
              <div
                class="text-subtitle-2 font-weight-light grey--text text--darken-2"
              >
                {{ goodsDelivery.branch_nhap_address }}
              </div>
              <div
                v-if="goodsDelivery.note_nhan"
                class="text-subtitle-2 font-weight-light yellow lighten-5 rounded px-2 py-1 mt-2"
              >
                <span class="font-weight-medium">Ghi chú:</span>
                {{ goodsDelivery.note_nhan }}
              </div>
            </v-col>
          </v-row>
        </v-card>

        <!-- Start: Staff -->
        <v-card
          class="mt-5"
          flat
          v-if="
            goodsDelivery.participants && goodsDelivery.participants.length > 0
          "
        >
          <v-row
            class="mx-0 px-5 py-0"
            v-for="staff in goodsDelivery.participants"
            :key="staff.id"
          >
            <v-col class="px-0">
              <div class="">
                <span class="font-weight-bold">Nhân viên: </span>
                <span v-if="staff && staff.user && staff.user.name">{{
                  staff.user.name
                }}</span>
              </div>
            </v-col>
            <v-col class="px-0">
              <div class="text-center">
                <span class="font-weight-bold">SĐT: </span>
                <span v-if="staff && staff.user && staff.user.phone">
                  {{ staff.user.phone | VMask("### ### ####") }}
                </span>
              </div>
            </v-col>
            <v-col class="px-0">
              <div class="text-center">
                <span class="font-weight-bold">Vai trò: </span>
                <span v-if="staff && staff.role === 'RECEIVING'">
                  Người nhận
                </span>
                <span v-if="staff && staff.role === 'PACKAGING'">
                  Giao hàng
                </span>
              </div>
            </v-col>
            <v-col class="px-0">
              <div
                class="text-center"
                v-if="staff && staff.role === 'RECEIVING'"
              >
                <span class="font-weight-bold">Ghi chú nhận: </span>
                <span v-if="staff && staff.note">{{ staff.note }}</span>
                <span v-else>-</span>
              </div>
              <div
                class="text-center"
                v-if="staff && staff.role === 'PACKAGING'"
              >
                <span class="font-weight-bold">Quãng đường: </span>
                <span v-if="staff.value"> {{ staff.value }} km</span>
                <span v-else>N/A</span>
              </div>
            </v-col>
          </v-row>
        </v-card>
        <!-- End: Staff -->

        <card-products-detail></card-products-detail>

        <v-card class="mt-5 py-4" flat>
          <v-row class="mx-0">
            <v-col class="py-0 px-5">
              <div class="font-weight-bold">
                Đơn vị vận chuyển
              </div>
              <div class="">
                {{
                  goodsDelivery.shipping_supplier &&
                  goodsDelivery.shipping_supplier.name
                    ? goodsDelivery.shipping_supplier.name
                    : "Chưa có đơn vị vận chuyển"
                }}
              </div>
            </v-col>
            <v-col class="py-0 px-5">
              <div class="font-weight-bold">
                Phí vận chuyển
              </div>
              <div class="">
                {{ goodsDelivery.fee | formatCurrency }}
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-container>
    </v-card>
  </tp-modal>
</template>

<script>
import CardProductsDetail from "./components/CardProductsDetail";

export default {
  components: {
    CardProductsDetail
  },
  computed: {
    goodsDelivery() {
      return this.$store.getters["GOODS_DELIVERY/goodsDelivery"];
    },
    goodsDeliveryStatusRequest() {
      return this.$store.getters["GOODS_DELIVERY/statusRequest"];
    }
  },
  filters: {
    formatCurrency(value) {
      let formatter = new Intl.NumberFormat("vi-VN", {
        style: "currency",
        currency: "VND"
      });

      return formatter.format(value);
    }
  },
  methods: {
    cancelGoodsDelivery(index) {
      this.$modal.show({
        name: "modal-alert",
        payload: {
          title: `<span>Hủy phiếu chuyển hàng <strong>${this.goodsDelivery.code}</strong></span>`,
          message:
            "Hành động này sẽ không thể hoàn tác. Bạn có chắc chắn muốn tiếp tục?",
          confirmBtn: {
            color: "red accent-2",
            text: "Tiếp tục",
            onClickHandler: async () => {
              await this.$store.dispatch("GOODS_DELIVERY/cancelGoodsDelivery", {
                id: this.goodsDelivery.id,
                index: index
              });
              if (
                this.goodsDeliveryStatusRequest.value ===
                "success-cancelGoodsDelivery"
              ) {
                this.goodsDelivery.status = -1;
                // this.$modal.hide({ name: "modal-goods-delivery" });
                // Alert
                this.$toast.show({
                  name: "toast-action-alert",
                  payload: {
                    message: "Đã hủy phiếu chuyển hàng"
                  }
                });
              }
            }
          }
        }
      });
    },

    closeModal() {
      this.$modal.hide({ name: "modal-goods-delivery" });
    },

    openModalGoodsDeliveryConfirm(index) {
      this.$modal.show({
        name: "modal-goods-delivery-confirm",
        payload: {
          item: this.goodsDelivery,
          index: index
        }
      });
    }
  }
};
</script>
